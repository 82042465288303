<template>
  <div class="min _bg-default">
    <nav class="pa-3">
      <v-btn dark color="indigo" @click="addModule">
        <v-icon dark> mdi-plus </v-icon>
        Tambah Modul
      </v-btn>
    </nav>
    <div class="temp-list d-flex">
      <v-card class="temp-table ma-5">
        <v-card-title>
          Daftar Modul
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <div>
          <v-data-table
            :loading="loadTable"
            :headers="headers"
            :items="allModul.data"
            :search="search"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-space-between">
                <v-btn fab dark small color="cyan">
                  <v-icon dark small @click="viewModule(item)">
                    mdi-eye
                  </v-icon>
                </v-btn>
                <v-btn fab dark small color="red" @click="delModule(item)">
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="paginate"></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { GET_MODULE, DEL_MODULE } from "../../graphql/Module";
export default {
  name: "listModule",
  // apollo query
  apollo: {
    allModul: {
      query: GET_MODULE,
      variables() {
        return { page: this.page, first: this.limit };
      },
      error(err) {
        console.log(err);
      }
    }
  },
  components: {},
  computed: {
    loadTable() {
      return this.$apollo.queries.allModul.loading;
    },
    paginate() {
      let page = null;
      if (this.allModul.paginatorInfo) {
        let total = this.allModul.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    }
  },
  data() {
    return {
      allModul: [],
      page: 1,
      limit: 10,
      role: null,
      search: "",
      headers: [
        { text: "id", value: "id", sortable: false },
        { text: "Nama Modul", value: "nama_modul" },
        {
          text: "Tipe",
          align: "start",
          sortable: false,
          value: "tipe"
        },
        { text: "Data Kasus", value: "modul_kasus.length" },
        { text: "Tugas", value: "modul_tugas.length" },
        { text: "Informasi Pendukung", value: "berita.length" },
        { text: "Aksi", value: "actions" }
      ]
    };
  },
  watch: {
    /* eslint-disable no-unused-vars */
    async $route(to, from) {
      /* eslint-enable no-unused-vars */
      console.log(to);
      await this.$apollo.queries.allModul.refetch();
    }
  },
  created() {
    this.role = localStorage.getItem("role_id");
    this.$apollo.queries.allModul.refetch();
  },
  methods: {
    addModule() {
      this.$router.push("add-module");
    },
    viewModule(item) {
      this.$router.push(`module-detail/${item.id}`);
    },
    delModule(item) {
      Swal.fire({
        title: "Yakin hapus modul?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          const id = parseInt(item.id);
          this.$apollo
            .mutate({
              mutation: DEL_MODULE,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.allModul.refetch();
              Swal.fire({
                icon: "success",
                title: "Modul telah terhapus",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              if (err) {
                Swal.fire({
                  icon: "error",
                  title: "Modul sudah di pakai",
                  showConfirmButton: false,
                  timer: 3000,
                  position: "top",
                  toast: true
                });
              }
            });
        }
      });
    }
  }
};
</script>

<style>
.profile-teacher {
  display: none;
}
.temp-table {
  width: 100%;
}
/* .temp-list {
  background: #f4f6f9;
}
nav {
  background: #f4f6f9;
} */
.min {
  min-height: 90vh;
}
._bg-default {
  background: #f4f6f9;
}
.module {
  width: calc(100% / 2);
}
.temp-mod {
  width: 100%;
}
</style>
