<template>
  <div>
    <listModule />
  </div>
</template>

<script>
import listModule from "../../components/Teacher/ListModule";

export default {
  name: "Module",
  components: {
    listModule
  }
};
</script>

<style></style>
